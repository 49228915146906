export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: { input: any; output: any; }
  /** Epoch time in seconds */
  EpochTime: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  addressDetails?: Maybe<Scalars['String']['output']>;
  addressLine: Scalars['String']['output'];
  city: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  outOfDeliveryZone: Scalars['Boolean']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
  zipcode: Scalars['String']['output'];
};

/** The connection type for Address. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Address>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Address>;
};

/** Application settings */
export type AppSettings = {
  __typename?: 'AppSettings';
  cuisineTypes: CuisineConnection;
};


/** Application settings */
export type AppSettingsCuisineTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of AssignAdmin */
export type AssignAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of AssignAdmin. */
export type AssignAdminPayload = {
  __typename?: 'AssignAdminPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  restaurant?: Maybe<Restaurant>;
};

export type Audit = {
  __typename?: 'Audit';
  action?: Maybe<Scalars['String']['output']>;
  auditableType: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
};

export type AutocompleteResult = {
  __typename?: 'AutocompleteResult';
  suggestions?: Maybe<Array<Suggestion>>;
};

export type Banner = IBanner & {
  __typename?: 'Banner';
  appLink?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  frontText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  sessionsNumber?: Maybe<Scalars['Int']['output']>;
  tapBehavior: Scalars['String']['output'];
  tappingText?: Maybe<Scalars['String']['output']>;
  versions: Array<BannerVersion>;
};

/** The connection type for Banner. */
export type BannerConnection = {
  __typename?: 'BannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Banner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Banner>;
};

export type BannerListComponent = IComponent & Node & {
  __typename?: 'BannerListComponent';
  banners: BannerConnection;
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};


export type BannerListComponentBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Banner version */
export type BannerVersion = {
  __typename?: 'BannerVersion';
  imageUrl: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type BasicSubscription = ISubscription & Node & {
  __typename?: 'BasicSubscription';
  aboveThresholdDeliveries: Scalars['Int']['output'];
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  currentOrdersCount: Scalars['Int']['output'];
  currentRebateFragment?: Maybe<PricingFragment>;
  currentRebateTotal?: Maybe<Scalars['Int']['output']>;
  currentSpendings?: Maybe<Scalars['Int']['output']>;
  deliveryFees: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  monthlyStartDate?: Maybe<Scalars['Int']['output']>;
  nextBillAt?: Maybe<Scalars['Int']['output']>;
  plan: Plan;
  savedAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalDeliveries: Scalars['Int']['output'];
  totalMonthlyOrders?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  uniqueId: Scalars['ID']['output'];
};

/** The connection type for BasicSubscription. */
export type BasicSubscriptionConnection = {
  __typename?: 'BasicSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BasicSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BasicSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BasicSubscriptionEdge = {
  __typename?: 'BasicSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BasicSubscription>;
};

/** Autogenerated input type of CancelShipment */
export type CancelShipmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

export type Category = ICategory & Node & {
  __typename?: 'Category';
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  dynamic: Scalars['Boolean']['output'];
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  /** Products by restaurant */
  products: ProductConnection;
  uniqueId: Scalars['ID']['output'];
};


export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

/** Autogenerated input type of CheckoutOrder */
export type CheckoutOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  deliveryWindowId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

export type CheckoutOrderOutput = {
  __typename?: 'CheckoutOrderOutput';
  checkedOutOrder: Order;
};

/** Autogenerated input type of CheckoutShoppingSession */
export type CheckoutShoppingSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveries: Array<ShoppingSessionDeliveryInput>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

export type CheckoutShoppingSessionOutput = {
  __typename?: 'CheckoutShoppingSessionOutput';
  feedbackItems?: Maybe<Array<FeedbackItem>>;
  shipments?: Maybe<Array<Shipment>>;
  shoppingSession: ShoppingSession;
};

/** Autogenerated input type of CreateMembership */
export type CreateMembershipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateMembershipOutput = {
  __typename?: 'CreateMembershipOutput';
  createdMembership: Membership;
};

/** Autogenerated input type of CreateOrderItem */
export type CreateOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
  packagingType: PackageTypeEnum;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  substitutable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOrderItemOutput = {
  __typename?: 'CreateOrderItemOutput';
  createdOrderItem: OrderItem;
  updatedOrder: Order;
};

/** Autogenerated input type of CreatePlaidToken */
export type CreatePlaidTokenInput = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['String']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreatePlaidToken. */
export type CreatePlaidTokenPayload = {
  __typename?: 'CreatePlaidTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  linkToken: Scalars['String']['output'];
};

/** Autogenerated input type of CreateRestaurantAddress */
export type CreateRestaurantAddressInput = {
  addressLine: Scalars['String']['input'];
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type CreateRestaurantAddressOutput = {
  __typename?: 'CreateRestaurantAddressOutput';
  createdAddress?: Maybe<Address>;
  currentAddress?: Maybe<Address>;
};

/** Autogenerated input type of CreateRestaurant */
export type CreateRestaurantInput = {
  address: Scalars['String']['input'];
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cuisineTypeId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  restaurantNote?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  vendorKey?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

/** Autogenerated return type of CreateRestaurant. */
export type CreateRestaurantPayload = {
  __typename?: 'CreateRestaurantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdRestaurant: Restaurant;
  user: User;
};

/** Autogenerated input type of CreateShoppingList */
export type CreateShoppingListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateShoppingListItem */
export type CreateShoppingListItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateShoppingListItem. */
export type CreateShoppingListItemPayload = {
  __typename?: 'CreateShoppingListItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdShoppingListItem: ShoppingListItem;
  shoppingList: ShoppingList;
};

/** Autogenerated return type of CreateShoppingList. */
export type CreateShoppingListPayload = {
  __typename?: 'CreateShoppingListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingList: ShoppingList;
};

/** Autogenerated input type of CreateShoppingSessionFeedback */
export type CreateShoppingSessionFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackItemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  freeText?: InputMaybe<Scalars['String']['input']>;
  judgement: JudgementEnum;
  shoppingSessionId: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateShoppingSessionItem */
export type CreateShoppingSessionItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  packagingType: PackageTypeEnum;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateSurvey */
export type CreateSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  surveyType: Scalars['String']['input'];
};

/** Autogenerated return type of CreateSurvey. */
export type CreateSurveyPayload = {
  __typename?: 'CreateSurveyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  survey?: Maybe<Survey>;
};

export type Cuisine = Node & {
  __typename?: 'Cuisine';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** ID of the object. */
  uniqueId: Scalars['ID']['output'];
};

/** The connection type for Cuisine. */
export type CuisineConnection = {
  __typename?: 'CuisineConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CuisineEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Cuisine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CuisineEdge = {
  __typename?: 'CuisineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Cuisine>;
};

export type CuisineTypeComponent = IComponent & Node & {
  __typename?: 'CuisineTypeComponent';
  banner: Banner;
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};

export type CustomerLead = {
  __typename?: 'CustomerLead';
  businessName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type DealOfTheWeekComponent = IComponent & Node & {
  __typename?: 'DealOfTheWeekComponent';
  banner: Banner;
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  products: Array<Product>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};

export type DedicatedRoutingGroup = {
  __typename?: 'DedicatedRoutingGroup';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** @deprecated routing_type is not actual anymore, always returns "dynamic" for compatibility */
  routingType: DedicatedRoutingGroupTypeEnum;
};

export enum DedicatedRoutingGroupTypeEnum {
  /** Dynamic routes */
  Dynamic = 'DYNAMIC',
  /** Static routes */
  Static = 'STATIC'
}

export type DefaultPlan = {
  __typename?: 'DefaultPlan';
  deliveryPricingMatrix: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Will be removed soon */
  pickUpPricingMatrix: Scalars['JSON']['output'];
  /** @deprecated Will be removed soon */
  promotedPricing: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of DeleteUser. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  restaurant?: Maybe<Restaurant>;
};

export type DeliveryInformation = {
  __typename?: 'DeliveryInformation';
  date: Scalars['EpochTime']['output'];
  packagingType: PackageTypeEnum;
  quantity: Scalars['Int']['output'];
};

export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  customerFacingMessage: Scalars['String']['output'];
  date: Scalars['Int']['output'];
  end: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  showLastSpotsLeft: Scalars['Boolean']['output'];
  spotsLeft: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

/** The connection type for DeliveryTime. */
export type DeliveryTimeConnection = {
  __typename?: 'DeliveryTimeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeliveryTimeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DeliveryTime>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DeliveryTimeEdge = {
  __typename?: 'DeliveryTimeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DeliveryTime>;
};

export type DeliveryZone = {
  __typename?: 'DeliveryZone';
  id: Scalars['Int']['output'];
  membershipPlan?: Maybe<Plan>;
  name: Scalars['String']['output'];
};

/** Autogenerated input type of DestroyOrderItem */
export type DestroyOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

export type DestroyOrderItemOutput = {
  __typename?: 'DestroyOrderItemOutput';
  updatedOrder: Order;
};

/** Autogenerated input type of DestroyPaymentMethod */
export type DestroyPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyPaymentMethod. */
export type DestroyPaymentMethodPayload = {
  __typename?: 'DestroyPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  restaurant: Restaurant;
};

/** Autogenerated input type of DestroyShipmentItem */
export type DestroyShipmentItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
  shipmentId: Scalars['Int']['input'];
};

export type DestroyShipmentItemOutput = {
  __typename?: 'DestroyShipmentItemOutput';
  shipment?: Maybe<Shipment>;
};

/** Autogenerated input type of DestroyShoppingList */
export type DestroyShoppingListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated input type of DestroyShoppingListItem */
export type DestroyShoppingListItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyShoppingListItem. */
export type DestroyShoppingListItemPayload = {
  __typename?: 'DestroyShoppingListItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingList: ShoppingList;
};

/** Autogenerated input type of DestroyShoppingListItems */
export type DestroyShoppingListItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['Int']['input']>;
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyShoppingListItems. */
export type DestroyShoppingListItemsPayload = {
  __typename?: 'DestroyShoppingListItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingList: ShoppingList;
};

/** Autogenerated return type of DestroyShoppingList. */
export type DestroyShoppingListPayload = {
  __typename?: 'DestroyShoppingListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DestroyShoppingSessionItem */
export type DestroyShoppingSessionItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of DestroyUserAccount */
export type DestroyUserAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DestroyUserAccountOutput = {
  __typename?: 'DestroyUserAccountOutput';
  success: Scalars['Boolean']['output'];
};

export type Dimensions = {
  __typename?: 'Dimensions';
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Autogenerated input type of EmptyShoppingSession */
export type EmptyShoppingSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

export type Facet = {
  __typename?: 'Facet';
  buckets: Array<FacetBucket>;
  filter: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FacetBucket = {
  __typename?: 'FacetBucket';
  count: Scalars['Int']['output'];
  val: Scalars['String']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['Int']['output'];
};

export type FeedbackItem = {
  __typename?: 'FeedbackItem';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
};

export type FeedbackOutput = {
  __typename?: 'FeedbackOutput';
  feedback: Feedback;
};

export enum FilterEnum {
  /** filter by category id */
  Category = 'CATEGORY',
  /** blended by chunks: top 1 from each category group */
  MostPopular = 'MOST_POPULAR',
  /** blended by chunks: top 3 from each category group */
  Popular = 'POPULAR'
}

/** Autogenerated input type of GenerateInvitationToken */
export type GenerateInvitationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated return type of GenerateInvitationToken. */
export type GenerateInvitationTokenPayload = {
  __typename?: 'GenerateInvitationTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invitationToken?: Maybe<InvitationToken>;
};

export type GoogleAiRecommendedProduct = {
  __typename?: 'GoogleAiRecommendedProduct';
  product: Product;
};

export type HomePage = {
  __typename?: 'HomePage';
  components: Array<HomePageUnion>;
};

/** Represents all possible components which can be shown on Home Page */
export type HomePageUnion = BannerListComponent | CuisineTypeComponent | DealOfTheWeekComponent | ProductListComponent | SalesRepresentativeComponent;

export type IBanner = {
  appLink?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type ICategory = {
  description?: Maybe<Scalars['String']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type IComponent = {
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type IComponentProducts = {
  products: ProductConnection;
};


export type IComponentProductsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ISubscription = {
  aboveThresholdDeliveries: Scalars['Int']['output'];
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  currentOrdersCount: Scalars['Int']['output'];
  currentRebateFragment?: Maybe<PricingFragment>;
  currentRebateTotal?: Maybe<Scalars['Int']['output']>;
  currentSpendings?: Maybe<Scalars['Int']['output']>;
  deliveryFees: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  monthlyStartDate?: Maybe<Scalars['Int']['output']>;
  nextBillAt?: Maybe<Scalars['Int']['output']>;
  plan: Plan;
  savedAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalDeliveries: Scalars['Int']['output'];
  totalMonthlyOrders?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  hqUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Autogenerated input type of ImportItemsFromOrder */
export type ImportItemsFromOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of ImportItemsFromPreviousShoppingSession */
export type ImportItemsFromPreviousShoppingSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of ImportItemsFromShipment */
export type ImportItemsFromShipmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
  shipmentId: Scalars['Int']['input'];
};

export type IncentivePlan = {
  __typename?: 'IncentivePlan';
  numberOfItems: Scalars['Int']['output'];
  packagingType: Scalars['String']['output'];
  planAmount: Scalars['Float']['output'];
  planAmountUnit: IncentivePlanAmountUnitEnum;
  planType: IncentivePlanTypeEnum;
};

export enum IncentivePlanAmountUnitEnum {
  /** Percentage */
  Percentage = 'PERCENTAGE'
}

export enum IncentivePlanTypeEnum {
  /** Credit */
  Credit = 'CREDIT'
}

export type InvitationToken = {
  __typename?: 'InvitationToken';
  expiresAt: Scalars['EpochTime']['output'];
  inviterName: Scalars['String']['output'];
  restaurantId: Scalars['Int']['output'];
  restaurantName: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['Int']['output'];
  total?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of JoinByInvitationToken */
export type JoinByInvitationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of JoinByInvitationToken. */
export type JoinByInvitationTokenPayload = {
  __typename?: 'JoinByInvitationTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The restaurant from invitation token */
  restaurant?: Maybe<Restaurant>;
};

export enum JudgementEnum {
  /** Negative judgement */
  Negative = 'NEGATIVE',
  /** Positive judgement */
  Positive = 'POSITIVE'
}

export type Membership = ISubscription & {
  __typename?: 'Membership';
  aboveThresholdDeliveries: Scalars['Int']['output'];
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  currentOrdersCount: Scalars['Int']['output'];
  currentRebateFragment?: Maybe<PricingFragment>;
  currentRebateTotal?: Maybe<Scalars['Int']['output']>;
  currentSpendings?: Maybe<Scalars['Int']['output']>;
  deliveryFees: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  monthlyStartDate?: Maybe<Scalars['Int']['output']>;
  nextBillAt?: Maybe<Scalars['Int']['output']>;
  plan: Plan;
  savedAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalDeliveries: Scalars['Int']['output'];
  totalMonthlyOrders?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

/** Represents either a membership, or a potential membership for non members */
export type MembershipUnion = Membership | NullMembership;

/** Autogenerated input type of MoveShoppingListItems */
export type MoveShoppingListItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fromShoppingListId: Scalars['Int']['input'];
  productIds: Array<Scalars['Int']['input']>;
  toShoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of MoveShoppingListItems. */
export type MoveShoppingListItemsPayload = {
  __typename?: 'MoveShoppingListItemsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fromShoppingList: ShoppingList;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelShipment?: Maybe<ShipmentOutput>;
  checkoutOrder?: Maybe<CheckoutOrderOutput>;
  checkoutShoppingSession?: Maybe<CheckoutShoppingSessionOutput>;
  createMembership?: Maybe<CreateMembershipOutput>;
  createOrderItem?: Maybe<CreateOrderItemOutput>;
  createPlaidToken?: Maybe<CreatePlaidTokenPayload>;
  createRestaurant?: Maybe<CreateRestaurantPayload>;
  createRestaurantAddress?: Maybe<CreateRestaurantAddressOutput>;
  createShoppingList?: Maybe<CreateShoppingListPayload>;
  createShoppingListItem?: Maybe<CreateShoppingListItemPayload>;
  createShoppingSessionFeedback?: Maybe<FeedbackOutput>;
  createShoppingSessionItem?: Maybe<ShoppingSessionOutput>;
  createSurvey?: Maybe<CreateSurveyPayload>;
  destroyOrderItem?: Maybe<DestroyOrderItemOutput>;
  destroyPaymentMethod?: Maybe<DestroyPaymentMethodPayload>;
  destroyShipmentItem?: Maybe<DestroyShipmentItemOutput>;
  destroyShoppingList?: Maybe<DestroyShoppingListPayload>;
  destroyShoppingListItem?: Maybe<DestroyShoppingListItemPayload>;
  destroyShoppingListItems?: Maybe<DestroyShoppingListItemsPayload>;
  destroyShoppingSessionItem?: Maybe<ShoppingSessionOutput>;
  destroyUserAccount?: Maybe<DestroyUserAccountOutput>;
  emptyShoppingSession?: Maybe<ShoppingSessionOutput>;
  importShoppingSessionItemsFromOrder?: Maybe<ShoppingSessionOutput>;
  importShoppingSessionItemsFromPreviousShoppingSession?: Maybe<ShoppingSessionOutput>;
  importShoppingSessionItemsFromShipment?: Maybe<ShoppingSessionOutput>;
  moveShoppingListItems?: Maybe<MoveShoppingListItemsPayload>;
  prepareCheckout?: Maybe<PrepareCheckoutOutput>;
  quickCheckout?: Maybe<ShoppingSessionOutput>;
  replaceShoppingListItem?: Maybe<ReplaceShoppingListItemPayload>;
  replaceShoppingSessionItem?: Maybe<ShoppingSessionOutput>;
  restaurantAssignAdmin?: Maybe<AssignAdminPayload>;
  restaurantDeleteUser?: Maybe<DeleteUserPayload>;
  restaurantGenerateInvitationToken?: Maybe<GenerateInvitationTokenPayload>;
  restaurantJoinByInvitationToken?: Maybe<JoinByInvitationTokenPayload>;
  updateOrder?: Maybe<UpdateOrderOutput>;
  updateOrderItem?: Maybe<UpdateOrderItemOutput>;
  updatePaymentMethodDefault?: Maybe<UpdatePaymentMethodDefaultPayload>;
  updatePositionShoppingList?: Maybe<UpdatePositionShoppingListPayload>;
  updatePositionShoppingListItem?: Maybe<UpdatePositionShoppingListItemPayload>;
  updateRestaurant?: Maybe<UpdateRestaurantPayload>;
  updateRestaurantAddress?: Maybe<UpdateRestaurantAddressOutput>;
  updateShipment?: Maybe<ShipmentOutput>;
  updateShipmentItem?: Maybe<ShipmentOutput>;
  updateShoppingList?: Maybe<UpdateShoppingListPayload>;
  updateShoppingSession?: Maybe<ShoppingSessionOutput>;
  updateShoppingSessionItem?: Maybe<ShoppingSessionOutput>;
};


export type MutationCancelShipmentArgs = {
  input: CancelShipmentInput;
};


export type MutationCheckoutOrderArgs = {
  input: CheckoutOrderInput;
};


export type MutationCheckoutShoppingSessionArgs = {
  input: CheckoutShoppingSessionInput;
};


export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


export type MutationCreateOrderItemArgs = {
  input: CreateOrderItemInput;
};


export type MutationCreatePlaidTokenArgs = {
  input: CreatePlaidTokenInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateRestaurantAddressArgs = {
  input: CreateRestaurantAddressInput;
};


export type MutationCreateShoppingListArgs = {
  input: CreateShoppingListInput;
};


export type MutationCreateShoppingListItemArgs = {
  input: CreateShoppingListItemInput;
};


export type MutationCreateShoppingSessionFeedbackArgs = {
  input: CreateShoppingSessionFeedbackInput;
};


export type MutationCreateShoppingSessionItemArgs = {
  input: CreateShoppingSessionItemInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationDestroyOrderItemArgs = {
  input: DestroyOrderItemInput;
};


export type MutationDestroyPaymentMethodArgs = {
  input: DestroyPaymentMethodInput;
};


export type MutationDestroyShipmentItemArgs = {
  input: DestroyShipmentItemInput;
};


export type MutationDestroyShoppingListArgs = {
  input: DestroyShoppingListInput;
};


export type MutationDestroyShoppingListItemArgs = {
  input: DestroyShoppingListItemInput;
};


export type MutationDestroyShoppingListItemsArgs = {
  input: DestroyShoppingListItemsInput;
};


export type MutationDestroyShoppingSessionItemArgs = {
  input: DestroyShoppingSessionItemInput;
};


export type MutationDestroyUserAccountArgs = {
  input: DestroyUserAccountInput;
};


export type MutationEmptyShoppingSessionArgs = {
  input: EmptyShoppingSessionInput;
};


export type MutationImportShoppingSessionItemsFromOrderArgs = {
  input: ImportItemsFromOrderInput;
};


export type MutationImportShoppingSessionItemsFromPreviousShoppingSessionArgs = {
  input: ImportItemsFromPreviousShoppingSessionInput;
};


export type MutationImportShoppingSessionItemsFromShipmentArgs = {
  input: ImportItemsFromShipmentInput;
};


export type MutationMoveShoppingListItemsArgs = {
  input: MoveShoppingListItemsInput;
};


export type MutationPrepareCheckoutArgs = {
  input: PrepareCheckoutInput;
};


export type MutationQuickCheckoutArgs = {
  input: QuickCheckoutInput;
};


export type MutationReplaceShoppingListItemArgs = {
  input: ReplaceShoppingListItemInput;
};


export type MutationReplaceShoppingSessionItemArgs = {
  input: ReplaceItemInput;
};


export type MutationRestaurantAssignAdminArgs = {
  input: AssignAdminInput;
};


export type MutationRestaurantDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationRestaurantGenerateInvitationTokenArgs = {
  input: GenerateInvitationTokenInput;
};


export type MutationRestaurantJoinByInvitationTokenArgs = {
  input: JoinByInvitationTokenInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrderItemArgs = {
  input: UpdateOrderItemInput;
};


export type MutationUpdatePaymentMethodDefaultArgs = {
  input: UpdatePaymentMethodDefaultInput;
};


export type MutationUpdatePositionShoppingListArgs = {
  input: UpdatePositionShoppingListInput;
};


export type MutationUpdatePositionShoppingListItemArgs = {
  input: UpdatePositionShoppingListItemInput;
};


export type MutationUpdateRestaurantArgs = {
  input: UpdateRestaurantInput;
};


export type MutationUpdateRestaurantAddressArgs = {
  input: UpdateRestaurantAddressInput;
};


export type MutationUpdateShipmentArgs = {
  input: UpdateShipmentInput;
};


export type MutationUpdateShipmentItemArgs = {
  input: UpdateShipmentItemInput;
};


export type MutationUpdateShoppingListArgs = {
  input: UpdateShoppingListInput;
};


export type MutationUpdateShoppingSessionArgs = {
  input: UpdateShoppingSessionInput;
};


export type MutationUpdateShoppingSessionItemArgs = {
  input: UpdateShoppingSessionItemInput;
};

export type NextShipment = {
  __typename?: 'NextShipment';
  customerCheckedOut: Scalars['Boolean']['output'];
  editUpTo?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  mainOrder?: Maybe<Order>;
  orders: OrdersConnection;
  shipDate?: Maybe<Scalars['Int']['output']>;
};


export type NextShipmentOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<OrdersFilter>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<OrdersSorting>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  uniqueId: Scalars['ID']['output'];
};

export type NullMembership = {
  __typename?: 'NullMembership';
  availableRebateFragments?: Maybe<Array<PricingFragment>>;
  plan: Plan;
  potentialRebate?: Maybe<Scalars['Int']['output']>;
  potentialSavings?: Maybe<Scalars['Int']['output']>;
  /** Whether to show membership upsell in delivery window section */
  suggestMoreDeliveryWindows?: Maybe<Scalars['Boolean']['output']>;
};

export type Order = Node & {
  __typename?: 'Order';
  availableDeliveryTimes: DeliveryTimeConnection;
  /**
   * Get all available pick up windows
   * @deprecated Will be removed soon
   */
  availablePickUpTimes: DeliveryTimeConnection;
  billed?: Maybe<Scalars['Boolean']['output']>;
  /** returns true only if order is under subscription and the order is eligible for free delivery regardless of plan orders limit */
  bypassPlanQuota?: Maybe<Scalars['Boolean']['output']>;
  cartTotal?: Maybe<Scalars['Int']['output']>;
  charged?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Int']['output'];
  creditCardCharge?: Maybe<Scalars['Int']['output']>;
  deliveryAddress?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['Int']['output']>;
  deliveryFee?: Maybe<Scalars['Int']['output']>;
  deliveryTimeEnd?: Maybe<Scalars['Int']['output']>;
  deliveryTimeStart?: Maybe<Scalars['Int']['output']>;
  deliveryWindowId?: Maybe<Scalars['Int']['output']>;
  editUpTo?: Maybe<Scalars['Int']['output']>;
  fees: Array<OrderFee>;
  fillIn: Scalars['Boolean']['output'];
  firstDeliveryDiscount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Will be removed soon */
  freeDeliveryDiscount?: Maybe<Scalars['Int']['output']>;
  freeDeliveryShortage?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Will be removed soon */
  freePickUpShortage?: Maybe<Scalars['Int']['output']>;
  fulfillmentMethod: Scalars['String']['output'];
  hasAlcoholItem: Scalars['Boolean']['output'];
  hasFrozenItem: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isRecurringOrder: Scalars['Boolean']['output'];
  lastAudit?: Maybe<Audit>;
  lastTimeModified?: Maybe<Scalars['Int']['output']>;
  manual?: Maybe<Scalars['Boolean']['output']>;
  minDeliveryFee: Scalars['Int']['output'];
  /** @deprecated Will be removed soon */
  minPickUpFee?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderDelivery?: Maybe<OrderDelivery>;
  orderItems: OrderItemsConnection;
  /** @deprecated Will be removed soon */
  pickUp?: Maybe<PickUp>;
  possibleFillIn: Scalars['Boolean']['output'];
  promoCode?: Maybe<PromoCode>;
  promoCodeDiscountCash?: Maybe<Scalars['Float']['output']>;
  promoCodeValidation: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon */
  promotedFulfillmentFee: Scalars['Boolean']['output'];
  /** Rebate amount for order */
  rebateAmount?: Maybe<Scalars['Int']['output']>;
  restaurant: Restaurant;
  /** @deprecated Will be removed soon */
  sameDayCharge?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Will be removed soon */
  sameDayChargeAmount: Scalars['Int']['output'];
  source?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subTotal?: Maybe<Scalars['BigInt']['output']>;
  /** Delta between sub total based on current prices and sub total based on frozen prices */
  subTotalFrozenDelta?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<BasicSubscription>;
  subscriptionFee?: Maybe<Scalars['Int']['output']>;
  taxes?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
  underSubscription: Scalars['Boolean']['output'];
  uniqueId: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
  warehouseExpectedDate?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};


export type OrderAvailableDeliveryTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderAvailablePickUpTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type OrderDelivery = Node & {
  __typename?: 'OrderDelivery';
  arrivedAt?: Maybe<Scalars['Int']['output']>;
  checkedOutAt?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Int']['output'];
  deliveredAt?: Maybe<Scalars['Int']['output']>;
  deliveryDate?: Maybe<Scalars['Int']['output']>;
  deliveryImage?: Maybe<Scalars['String']['output']>;
  deliveryTimeEnd?: Maybe<Scalars['Int']['output']>;
  deliveryTimeStart?: Maybe<Scalars['Int']['output']>;
  departedAt?: Maybe<Scalars['Int']['output']>;
  departureAt?: Maybe<Scalars['Int']['output']>;
  driver?: Maybe<Scalars['String']['output']>;
  estimatedArrivalTime?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Will be removed soon (always returns 'delivery') */
  fulfillmentType?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoice?: Maybe<Invoice>;
  mapUrl?: Maybe<Scalars['String']['output']>;
  nextStopNumber?: Maybe<Scalars['Int']['output']>;
  orderId: Scalars['Int']['output'];
  outOfStockItems: OutOfStockConnection;
  /** @deprecated Will be removed soon */
  pickUp?: Maybe<PickUp>;
  productsCount?: Maybe<Scalars['Int']['output']>;
  refusedItems: RefusalConnection;
  shipmentInvoices?: Maybe<Array<Invoice>>;
  shipmentOrderIds: Array<Scalars['Int']['output']>;
  shipmentOutOfStockItems: OutOfStockConnection;
  shipmentProductsCount?: Maybe<Scalars['Int']['output']>;
  shipmentRefusedItems: RefusalConnection;
  signatureImage?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['Int']['output']>;
  signedBy?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stopNumber?: Maybe<Scalars['Int']['output']>;
  truckName?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};


export type OrderDeliveryOutOfStockItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderDeliveryRefusedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderDeliveryShipmentOutOfStockItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderDeliveryShipmentRefusedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for OrderDelivery. */
export type OrderDeliveryConnection = {
  __typename?: 'OrderDeliveryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderDeliveryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrderDelivery>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderDeliveryEdge = {
  __typename?: 'OrderDeliveryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderDelivery>;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderFee = {
  __typename?: 'OrderFee';
  amount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: OrderFeeKind;
};

export enum OrderFeeKind {
  /** delivery fee */
  Delivery = 'DELIVERY',
  /** fuel surcharge fee */
  FuelSurcharge = 'FUEL_SURCHARGE'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['Int']['output'];
  incentivePlan?: Maybe<IncentivePlan>;
  lastAudit?: Maybe<Audit>;
  orderId: Scalars['Int']['output'];
  originalItem?: Maybe<OriginalItem>;
  packagingType: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Product;
  quantity: Scalars['Float']['output'];
  recurringOrderItem?: Maybe<RecurringOrderItem>;
  subTotal: Scalars['BigInt']['output'];
  substitutable: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon */
  thirdParty: Scalars['Boolean']['output'];
};

export type OrderItemAtpResult = {
  __typename?: 'OrderItemAtpResult';
  id: Scalars['Int']['output'];
  incentivePlan?: Maybe<IncentivePlan>;
  isAvailable: Scalars['Boolean']['output'];
  lastAudit?: Maybe<Audit>;
  orderId: Scalars['Int']['output'];
  originalItem?: Maybe<OriginalItem>;
  originalQuantity: Scalars['Float']['output'];
  packagingType: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Product;
  quantity: Scalars['Float']['output'];
  recurringOrderItem?: Maybe<RecurringOrderItem>;
  subTotal: Scalars['BigInt']['output'];
  substitutable: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon */
  thirdParty: Scalars['Boolean']['output'];
};

/** The connection type for OrderItem. */
export type OrderItemsConnection = {
  __typename?: 'OrderItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrderItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderItemsEdge = {
  __typename?: 'OrderItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItem>;
};

/** The connection type for Order. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrdersEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** Attributes for filtering given scope */
export type OrdersFilter = {
  /** Value of argument utilized by the scope described in 'with' */
  param?: InputMaybe<Scalars['String']['input']>;
  /** Name of the scope applied to the base result */
  with?: InputMaybe<OrdersFilterEnum>;
};

export enum OrdersFilterEnum {
  /** filter by jit orders pending arrival */
  AwaitingWarehouseArrival = 'AWAITING_WAREHOUSE_ARRIVAL',
  /** filter by delivered or billed statuses */
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  /** filter by jit orders */
  Jit = 'JIT',
  /** filter by stocked (non jit) orders */
  Stocked = 'STOCKED'
}

/** sorting args */
export type OrdersSorting = {
  /** sort by id */
  id?: InputMaybe<SortDirectionEnum>;
};

export type OriginalItem = {
  __typename?: 'OriginalItem';
  match?: Maybe<Scalars['String']['output']>;
  originalAttributes: Scalars['JSON']['output'];
  packagingType?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type OutOfStock = {
  __typename?: 'OutOfStock';
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  originalQuantity: Scalars['Float']['output'];
  packageType: Scalars['String']['output'];
  product: Product;
  quantity: Scalars['Float']['output'];
};

/** The connection type for OutOfStock. */
export type OutOfStockConnection = {
  __typename?: 'OutOfStockConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OutOfStockEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OutOfStock>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OutOfStockEdge = {
  __typename?: 'OutOfStockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OutOfStock>;
};

export enum PackageTypeEnum {
  /** Case packaging type */
  Case = 'CASE',
  /** Unit packaging type */
  Unit = 'UNIT'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationMetadata = {
  __typename?: 'PaginationMetadata';
  currentPage?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PaymentBlock = {
  __typename?: 'PaymentBlock';
  debt: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  backup?: Maybe<Scalars['Boolean']['output']>;
  default: Scalars['Boolean']['output'];
  failMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastDigits?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethodType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** The connection type for PaymentMethod. */
export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentMethodEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentMethod>;
};

export type Photo = {
  __typename?: 'Photo';
  imageUrl: Scalars['String']['output'];
};

export type PickUp = {
  __typename?: 'PickUp';
  address: Address;
  /** Distance for restaurant */
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type PickUpDistanceArgs = {
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for PickUp. */
export type PickUpConnection = {
  __typename?: 'PickUpConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PickUpEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PickUp>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PickUpEdge = {
  __typename?: 'PickUpEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PickUp>;
};

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']['output']>;
  descriptions?: Maybe<Scalars['String']['output']>;
  detailedDescription?: Maybe<Scalars['String']['output']>;
  /** orders with cart total over this threshold won't be deducted from remaining free order, and the delivery would be free */
  freeDeliveryThreshold?: Maybe<Scalars['Int']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** number of free orders */
  ordersLimit?: Maybe<Scalars['Int']['output']>;
  /** fee for every order over the orders limit */
  overLimitDeliveryFee?: Maybe<Scalars['Int']['output']>;
  overLimitType: Scalars['String']['output'];
  /** price of the plan */
  price?: Maybe<Scalars['Int']['output']>;
  priceDescription?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  rebate?: Maybe<PlanRebate>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** all orders are free, unlimited */
  unlimited?: Maybe<Scalars['Boolean']['output']>;
};

export type PlanRebate = {
  __typename?: 'PlanRebate';
  description?: Maybe<Scalars['String']['output']>;
  rebateMatrix: PricingMatrix;
  title: Scalars['String']['output'];
};

/** Autogenerated input type of PrepareCheckout */
export type PrepareCheckoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

export type PrepareCheckoutOutput = {
  __typename?: 'PrepareCheckoutOutput';
  restaurant: Restaurant;
  shoppingSession: ShoppingSession;
};

export type PriceLevel = {
  __typename?: 'PriceLevel';
  catchweightPrice?: Maybe<Scalars['Int']['output']>;
  context?: Maybe<PriceLevelContext>;
  discountedPrice?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type PriceLevelContext = {
  __typename?: 'PriceLevelContext';
  price: Scalars['Int']['output'];
  uom: Scalars['String']['output'];
};

export type PricingFragment = {
  __typename?: 'PricingFragment';
  description?: Maybe<Scalars['String']['output']>;
  endAmount?: Maybe<Scalars['String']['output']>;
  feeAmount: Scalars['Int']['output'];
  feeType: Scalars['String']['output'];
  startAmount?: Maybe<Scalars['String']['output']>;
};

export type PricingMatrix = {
  __typename?: 'PricingMatrix';
  creditCardFee: Scalars['Int']['output'];
  fragments: Array<PricingFragment>;
  key: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  atp: Scalars['Boolean']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  catalogName: Scalars['String']['output'];
  catchweightItem: Scalars['Boolean']['output'];
  categories: CategoryConnection;
  customerExpectedDate?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discontinued: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon (always false) */
  displaySubstitutableToggle: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  groupedByCategoryName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inStock: Scalars['Boolean']['output'];
  jit: Scalars['Boolean']['output'];
  mainCategory?: Maybe<Category>;
  name: Scalars['String']['output'];
  nextReceiveDate?: Maybe<Scalars['Int']['output']>;
  nextReceiveDateBuffer?: Maybe<Scalars['Int']['output']>;
  packer: Scalars['Boolean']['output'];
  /** Promotions by restaurant */
  promotions: Array<Promotion>;
  props: Array<Scalars['Int']['output']>;
  replacements: Array<Product>;
  sku: Scalars['String']['output'];
  specs: Array<Spec>;
  subCategory?: Maybe<Category>;
  upcs: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
  /** Product Variants by restaurant */
  variants: Array<Variant>;
  volumeDiscounts: Array<VolumeDiscount>;
  warehouseExpectedDate?: Maybe<Scalars['Int']['output']>;
};


export type ProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPromotionsArgs = {
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantsArgs = {
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

export type ProductListComponent = IComponent & IComponentProducts & Node & {
  __typename?: 'ProductListComponent';
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  products: ProductConnection;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};


export type ProductListComponentProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  amount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  minimum?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  promoCode?: Maybe<Scalars['String']['output']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  discountUnit: Scalars['String']['output'];
  discountValue: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Int']['output']>;
};

export type PromotionCategory = ICategory & {
  __typename?: 'PromotionCategory';
  description?: Maybe<Scalars['String']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Get application settings */
  appSettings: AppSettings;
  /** Autocomplete suggestions */
  autocomplete: AutocompleteResult;
  /** Get customer lead data */
  customerLead?: Maybe<CustomerLead>;
  /** Fetches an object given its unique ID */
  node?: Maybe<Node>;
  /** Get restaurant of current customer */
  restaurant: Restaurant;
  /** Get information about an invitation token of the restaurant */
  restaurantInvitationToken?: Maybe<InvitationToken>;
  /** Search products in SOLR */
  search: SearchResult;
  /** Get current user */
  user: User;
};


export type QueryAutocompleteArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};


export type QueryCustomerLeadArgs = {
  token: Scalars['String']['input'];
};


export type QueryNodeArgs = {
  uniqueId: Scalars['ID']['input'];
};


export type QueryRestaurantArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRestaurantInvitationTokenArgs = {
  token: Scalars['String']['input'];
};


export type QuerySearchArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<SearchFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of QuickCheckout */
export type QuickCheckoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

export type RecommendationData = {
  __typename?: 'RecommendationData';
  attributionToken?: Maybe<Scalars['String']['output']>;
  recommended?: Maybe<Array<RecommendationUnion>>;
};

export enum RecommendationSourceEnum {
  /** Shopping cart expansion */
  FrequentlyBoughtTogether = 'FREQUENTLY_BOUGHT_TOGETHER',
  /** based on the viewing history of the user and the candidate product's relevance(!!!SKU required) */
  OthersYouMayLike = 'OTHERS_YOU_MAY_LIKE',
  /** It provides the catalog of items the user has recently interacted with */
  RecentlyViewed = 'RECENTLY_VIEWED',
  /** Based on the shopping or viewing history of that user */
  RecommendedForYou = 'RECOMMENDED_FOR_YOU'
}

/** Represents products suggestions from AI API or VoidMatrix */
export type RecommendationUnion = GoogleAiRecommendedProduct | RecommendedProduct;

export type RecommendedCategory = ICategory & {
  __typename?: 'RecommendedCategory';
  description?: Maybe<Scalars['String']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  recommendedProducts: Array<RecommendedProduct>;
  subCategories: Array<Category>;
};


export type RecommendedCategoryRecommendedProductsArgs = {
  filter?: InputMaybe<Array<RecommendedProductsFilter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
};

export type RecommendedProduct = {
  __typename?: 'RecommendedProduct';
  priority: Scalars['Int']['output'];
  product: Product;
};

/** Attributes for filtering given scope */
export type RecommendedProductsFilter = {
  /** Value of argument utilized by the scope described in 'with' */
  param?: InputMaybe<Scalars['String']['input']>;
  /** Name of the scope applied to the base result */
  with?: InputMaybe<FilterEnum>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  interval: RecurrenceIntervalEnum;
  intervalDays: Array<Scalars['Int']['output']>;
};

export enum RecurrenceIntervalEnum {
  /** Weekly */
  Weekly = 'WEEKLY'
}

export type RecurringOrder = Node & {
  __typename?: 'RecurringOrder';
  id: Scalars['Int']['output'];
  itemsCount: Scalars['Int']['output'];
  recurrence: Recurrence;
  recurringOrderItems: RecurringOrderItemConnection;
  uniqueId: Scalars['ID']['output'];
};


export type RecurringOrderRecurringOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for RecurringOrder. */
export type RecurringOrderConnection = {
  __typename?: 'RecurringOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RecurringOrderEdge = {
  __typename?: 'RecurringOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringOrder>;
};

export type RecurringOrderItem = {
  __typename?: 'RecurringOrderItem';
  id: Scalars['Int']['output'];
  incentivePlan?: Maybe<IncentivePlan>;
  packagingType: Scalars['String']['output'];
  product: Product;
  quantity: Scalars['Int']['output'];
};

/** The connection type for RecurringOrderItem. */
export type RecurringOrderItemConnection = {
  __typename?: 'RecurringOrderItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RecurringOrderItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RecurringOrderItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RecurringOrderItemEdge = {
  __typename?: 'RecurringOrderItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecurringOrderItem>;
};

export type Refusal = {
  __typename?: 'Refusal';
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  packageType: Scalars['String']['output'];
  product: Product;
  rejectedQuantity: Scalars['Float']['output'];
};

/** The connection type for Refusal. */
export type RefusalConnection = {
  __typename?: 'RefusalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RefusalEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Refusal>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RefusalEdge = {
  __typename?: 'RefusalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Refusal>;
};

/** Autogenerated input type of ReplaceItem */
export type ReplaceItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  discontinuedProductId: Scalars['Int']['input'];
  packagingType: PackageTypeEnum;
  quantity: Scalars['Int']['input'];
  replacementProductId: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of ReplaceShoppingListItem */
export type ReplaceShoppingListItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  discontinuedProductId: Scalars['Int']['input'];
  replacementProductId: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
  shoppingListId?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of ReplaceShoppingListItem. */
export type ReplaceShoppingListItemPayload = {
  __typename?: 'ReplaceShoppingListItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  replacedShoppingListItem: ShoppingListItem;
  shoppingList: ShoppingList;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  addresses: AddressConnection;
  creditCardOnFile: Scalars['Boolean']['output'];
  cuisineType?: Maybe<Cuisine>;
  currentAddress?: Maybe<Address>;
  currentOrder: Order;
  currentPaymentMethod?: Maybe<PaymentMethod>;
  currentShoppingSession: ShoppingSession;
  currentStore?: Maybe<Store>;
  currentStoreContext: StoreContext;
  /** @deprecated Will be removed soon */
  customerSegment: Scalars['String']['output'];
  dedicatedRoutingGroup?: Maybe<DedicatedRoutingGroup>;
  /** Restaurant home_page field resolver */
  homePage: HomePage;
  id: Scalars['Int']['output'];
  localTimeNow: Scalars['Int']['output'];
  membership?: Maybe<MembershipUnion>;
  name?: Maybe<Scalars['String']['output']>;
  orderDeliveries: OrderDeliveryConnection;
  orders: OrderConnection;
  paymentBlock?: Maybe<PaymentBlock>;
  paymentMethods?: Maybe<PaymentMethodConnection>;
  paymentOption?: Maybe<Scalars['Int']['output']>;
  /** Recommendations with attribution token */
  recommendationData: RecommendationData;
  recommendations: Array<RecommendationUnion>;
  recurringOrders: RecurringOrderConnection;
  restaurantNote: RestaurantNote;
  shipments: ShipmentConnection;
  shoppingLists: ShoppingListConnection;
  subscriptions: BasicSubscriptionConnection;
  timeZone: Scalars['String']['output'];
  userRole: Scalars['String']['output'];
  users: UserConnection;
};


export type RestaurantAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantOrderDeliveriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantRecommendationDataArgs = {
  sku?: InputMaybe<Scalars['String']['input']>;
  source: RecommendationSourceEnum;
};


export type RestaurantRecommendationsArgs = {
  sku?: InputMaybe<Scalars['String']['input']>;
  source: RecommendationSourceEnum;
};


export type RestaurantRecurringOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantShipmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<ShipmentsFilter>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShipmentSorting>;
};


export type RestaurantShoppingListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RestaurantUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Restaurant. */
export type RestaurantConnection = {
  __typename?: 'RestaurantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RestaurantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Restaurant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RestaurantEdge = {
  __typename?: 'RestaurantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  /** The item at the end of the edge. */
  node: Restaurant;
};

export type RestaurantNote = {
  __typename?: 'RestaurantNote';
  id: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type SalesRepresentative = {
  __typename?: 'SalesRepresentative';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SalesRepresentativeComponent = IComponent & IComponentProducts & Node & {
  __typename?: 'SalesRepresentativeComponent';
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  products: ProductConnection;
  representativeInfo: SalesRepresentative;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['ID']['output'];
};


export type SalesRepresentativeComponentProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Attributes for filtering given scope */
export type SearchFilter = {
  availableNow?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Scalars['String']['input']>;
  firstTierSpec?: InputMaybe<Array<Scalars['String']['input']>>;
  jit?: InputMaybe<Scalars['Boolean']['input']>;
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  stocked?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  data: Array<Product>;
  facets: Array<Facet>;
  meta: PaginationMetadata;
  sorts: Array<Sort>;
  suid?: Maybe<Scalars['String']['output']>;
};

export type Shipment = Node & {
  __typename?: 'Shipment';
  availableDeliveryTimes?: Maybe<DeliveryTimeConnection>;
  cancelable: Scalars['Boolean']['output'];
  cartTotal: Scalars['Int']['output'];
  customerFacingMessage?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['Int']['output']>;
  deliveryFee: Scalars['Int']['output'];
  deliveryTimeEnd?: Maybe<Scalars['Int']['output']>;
  deliveryTimeStart?: Maybe<Scalars['Int']['output']>;
  deliveryWindowId?: Maybe<Scalars['Int']['output']>;
  freeShortage?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  orders: OrdersConnection;
  productsCount: Scalars['Int']['output'];
  shipmentDelivery?: Maybe<OrderDelivery>;
  status: ShipmentStatusEnum;
  subTotal: Scalars['Int']['output'];
  subTotalFrozenDelta?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
  uniqueId: Scalars['ID']['output'];
};


export type ShipmentAvailableDeliveryTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShipmentOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Shipment. */
export type ShipmentConnection = {
  __typename?: 'ShipmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShipmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Shipment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShipmentEdge = {
  __typename?: 'ShipmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Shipment>;
};

export type ShipmentOutput = {
  __typename?: 'ShipmentOutput';
  shipment: Shipment;
};

/** sorting args */
export type ShipmentSorting = {
  /** sort by id */
  id?: InputMaybe<SortDirectionEnum>;
  /** sort by ship date */
  shipDate?: InputMaybe<SortDirectionEnum>;
};

export enum ShipmentStatusEnum {
  /** shipment is open for new items and changes */
  InProgress = 'IN_PROGRESS',
  /** shipment was processed and scheduled */
  OnDelivery = 'ON_DELIVERY'
}

/** Attributes for filtering given scope */
export type ShipmentsFilter = {
  /** Value of argument utilized by the scope described in 'with' */
  param?: InputMaybe<Scalars['String']['input']>;
  /** Name of the scope applied to the base result */
  with?: InputMaybe<ShipmentsFilterEnum>;
};

export enum ShipmentsFilterEnum {
  /** shipment is open for new items and changes */
  InProgress = 'IN_PROGRESS',
  /** shipment was processed and scheduled */
  OnDelivery = 'ON_DELIVERY',
  /** Filter by equal to or greater than the ship date. */
  ShipDate = 'SHIP_DATE'
}

export type ShoppingList = {
  __typename?: 'ShoppingList';
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** @deprecated Use lastTimeModifiedMs instead */
  lastTimeModified: Scalars['Int']['output'];
  lastTimeModifiedMs: Scalars['BigInt']['output'];
  /** Get shopping list item by product id */
  listItemByProduct?: Maybe<ShoppingListItem>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  shoppingListItems: ShoppingListItemConnection;
  shoppingListItemsForCleanUp: ShoppingListItemConnection;
};


export type ShoppingListListItemByProductArgs = {
  productId: Scalars['Int']['input'];
};


export type ShoppingListShoppingListItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShoppingListShoppingListItemsForCleanUpArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ShoppingList. */
export type ShoppingListConnection = {
  __typename?: 'ShoppingListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShoppingListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShoppingList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShoppingListEdge = {
  __typename?: 'ShoppingListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ShoppingList>;
};

export type ShoppingListItem = {
  __typename?: 'ShoppingListItem';
  createdAt: Scalars['Int']['output'];
  discontinued: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lastDeliveryInformation?: Maybe<DeliveryInformation>;
  originalItem?: Maybe<OriginalItem>;
  position?: Maybe<Scalars['Int']['output']>;
  product: Product;
  /** @deprecated Will be removed soon */
  source: Scalars['String']['output'];
  /** @deprecated Will be removed soon */
  substitutable: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon */
  thirdParty: Scalars['Boolean']['output'];
};

/** The connection type for ShoppingListItem. */
export type ShoppingListItemConnection = {
  __typename?: 'ShoppingListItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShoppingListItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShoppingListItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShoppingListItemEdge = {
  __typename?: 'ShoppingListItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ShoppingListItem>;
};

export type ShoppingSession = Node & {
  __typename?: 'ShoppingSession';
  /** @deprecated Will be removed soon */
  availablePickUps: Scalars['Boolean']['output'];
  /** returns true only if shopping session is under subscription and the orders are eligible for free delivery regardless of plan orders limit */
  bypassPlanQuota?: Maybe<Scalars['Boolean']['output']>;
  cartTotal?: Maybe<Scalars['Int']['output']>;
  creditCardCharge?: Maybe<Scalars['Int']['output']>;
  deliveries: Array<ShoppingSessionDelivery>;
  id: Scalars['Int']['output'];
  minDeliveryFee: Scalars['Int']['output'];
  /** @deprecated Will be removed soon */
  minPickUpFee?: Maybe<Scalars['Int']['output']>;
  moveItemsDeliveries?: Maybe<Array<ShoppingSessionDelivery>>;
  /** @deprecated Will be removed soon */
  pickUp?: Maybe<PickUp>;
  /** @deprecated Will be removed soon */
  pickUpDeliveries: Array<ShoppingSessionDelivery>;
  promoCode?: Maybe<PromoCode>;
  promoCodeDiscountCash?: Maybe<Scalars['Float']['output']>;
  promoCodeValidation: Scalars['Boolean']['output'];
  /** @deprecated Will be removed soon */
  promotedFulfillmentFee: Scalars['Boolean']['output'];
  quickCheckoutAvailable: Scalars['Boolean']['output'];
  /** Rebate amount for shopping session */
  rebateAmount?: Maybe<Scalars['Int']['output']>;
  subTotal?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  underSubscription: Scalars['Boolean']['output'];
  uniqueId: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
  updatedItemsInformation?: Maybe<Array<OrderItemAtpResult>>;
};

export type ShoppingSessionDelivery = {
  __typename?: 'ShoppingSessionDelivery';
  availableDeliveryTimes: DeliveryTimeConnection;
  cartTotal?: Maybe<Scalars['Int']['output']>;
  customerFacingMessage?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['Int']['output']>;
  deliveryEnd?: Maybe<Scalars['Int']['output']>;
  deliveryFee?: Maybe<Scalars['Int']['output']>;
  deliveryStart?: Maybe<Scalars['Int']['output']>;
  deliveryWindowId?: Maybe<Scalars['Int']['output']>;
  fees: Array<OrderFee>;
  freeShortage?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  orders: OrderConnection;
  /** @deprecated Will be removed soon */
  pickUpId?: Maybe<Scalars['Int']['output']>;
  productsCount: Scalars['Int']['output'];
  subTotal?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};


export type ShoppingSessionDeliveryAvailableDeliveryTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShoppingSessionDeliveryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ShoppingSessionDeliveryInput = {
  deliveryDate: Scalars['ISO8601Date']['input'];
  deliveryTimeEnd: Scalars['Int']['input'];
  deliveryTimeStart: Scalars['Int']['input'];
  deliveryWindowId: Scalars['Int']['input'];
  orderIds: Array<Scalars['Int']['input']>;
};

export type ShoppingSessionOutput = {
  __typename?: 'ShoppingSessionOutput';
  shoppingSession: ShoppingSession;
};

/** Autogenerated return type of ShoppingSessionUpdated. */
export type ShoppingSessionUpdatedPayload = {
  __typename?: 'ShoppingSessionUpdatedPayload';
  audit?: Maybe<Audit>;
  message?: Maybe<Scalars['String']['output']>;
  shoppingSession: ShoppingSession;
};

export type Sort = {
  __typename?: 'Sort';
  name: Scalars['String']['output'];
  sort: Scalars['String']['output'];
};

export enum SortDirectionEnum {
  /** ascending */
  Asc = 'ASC',
  /** descending */
  Desc = 'DESC'
}

export type Spec = {
  __typename?: 'Spec';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  specType: Scalars['String']['output'];
  tier: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type Store = {
  __typename?: 'Store';
  /** Add ID to include jit category for specific restaurant */
  categories: CategoryConnection;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Will be removed soon */
  pickUps: PickUpConnection;
  /** @deprecated Will be removed soon */
  pickUpsEnabled: Scalars['Boolean']['output'];
  priceFreezePolicy: Scalars['Boolean']['output'];
  productBySku?: Maybe<Product>;
  products: ProductConnection;
  promotionCategory?: Maybe<PromotionCategory>;
  vendor: Vendor;
};


export type StoreCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forSidebar?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};


export type StorePickUpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type StoreProductBySkuArgs = {
  sku?: InputMaybe<Scalars['String']['input']>;
};


export type StoreProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreContext = {
  __typename?: 'StoreContext';
  defaultPricingPlan?: Maybe<DefaultPlan>;
  deliveryZone?: Maybe<DeliveryZone>;
  id: Scalars['Int']['output'];
  nextShipment?: Maybe<NextShipment>;
  orders: Array<Order>;
  /** @deprecated Low usage */
  recommendedCategory?: Maybe<RecommendedCategory>;
  shoppingList?: Maybe<ShoppingList>;
};


export type StoreContextOrdersArgs = {
  filter?: InputMaybe<Array<OrdersFilter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<OrdersSorting>;
};

export type SubscriptionQuery = {
  __typename?: 'SubscriptionQuery';
  shoppingSessionUpdated: ShoppingSessionUpdatedPayload;
};


export type SubscriptionQueryShoppingSessionUpdatedArgs = {
  restaurantId: Scalars['Int']['input'];
};

export type Suggestion = {
  __typename?: 'Suggestion';
  title: Scalars['String']['output'];
};

export type Survey = {
  __typename?: 'Survey';
  code: Scalars['String']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  surveyType: Scalars['String']['output'];
};

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  deliveryWindowId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateOrderItem */
export type UpdateOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  substitutable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOrderItemOutput = {
  __typename?: 'UpdateOrderItemOutput';
  updatedOrder: Order;
  updatedOrderItem: OrderItem;
};

export type UpdateOrderOutput = {
  __typename?: 'UpdateOrderOutput';
  updatedOrder: Order;
};

/** Autogenerated input type of UpdatePaymentMethodDefault */
export type UpdatePaymentMethodDefaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePaymentMethodDefault. */
export type UpdatePaymentMethodDefaultPayload = {
  __typename?: 'UpdatePaymentMethodDefaultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  restaurant: Restaurant;
};

/** Autogenerated input type of UpdatePositionShoppingList */
export type UpdatePositionShoppingListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated input type of UpdatePositionShoppingListItem */
export type UpdatePositionShoppingListItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePositionShoppingListItem. */
export type UpdatePositionShoppingListItemPayload = {
  __typename?: 'UpdatePositionShoppingListItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingList: ShoppingList;
};

/** Autogenerated return type of UpdatePositionShoppingList. */
export type UpdatePositionShoppingListPayload = {
  __typename?: 'UpdatePositionShoppingListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingLists: ShoppingListConnection;
};


/** Autogenerated return type of UpdatePositionShoppingList. */
export type UpdatePositionShoppingListPayloadShoppingListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateRestaurantAddress */
export type UpdateRestaurantAddressInput = {
  addressLine: Scalars['String']['input'];
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type UpdateRestaurantAddressOutput = {
  __typename?: 'UpdateRestaurantAddressOutput';
  currentAddress?: Maybe<Address>;
  updatedAddress?: Maybe<Address>;
};

/** Autogenerated input type of UpdateRestaurant */
export type UpdateRestaurantInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cuisineTypeId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  restaurantId?: InputMaybe<Scalars['Int']['input']>;
  restaurantNote?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateRestaurant. */
export type UpdateRestaurantPayload = {
  __typename?: 'UpdateRestaurantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  restaurant: Restaurant;
};

/** Autogenerated input type of UpdateShipment */
export type UpdateShipmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryDate: Scalars['ISO8601Date']['input'];
  deliveryTimeEnd: Scalars['Int']['input'];
  deliveryTimeStart: Scalars['Int']['input'];
  deliveryWindowId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of UpdateShipmentItem */
export type UpdateShipmentItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['Int']['input'];
  /** New Order Item Quantity */
  quantity: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
  shipmentId: Scalars['Int']['input'];
};

/** Autogenerated input type of UpdateShoppingList */
export type UpdateShoppingListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shoppingListId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateShoppingList. */
export type UpdateShoppingListPayload = {
  __typename?: 'UpdateShoppingListPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shoppingList: ShoppingList;
};

/** Autogenerated input type of UpdateShoppingSession */
export type UpdateShoppingSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveries?: InputMaybe<Array<ShoppingSessionDeliveryInput>>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  restaurantId: Scalars['Int']['input'];
};

/** Autogenerated input type of UpdateShoppingSessionItem */
export type UpdateShoppingSessionItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['Int']['input'];
  packagingType: PackageTypeEnum;
  quantity: Scalars['Int']['input'];
  restaurantId: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  badge?: Maybe<Scalars['Int']['output']>;
  banners: Array<Banner>;
  canRate?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notifyByEmail?: Maybe<Scalars['Boolean']['output']>;
  notifyOosTxtMessages?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rated?: Maybe<Scalars['Boolean']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  restaurants: RestaurantConnection;
  verified?: Maybe<Scalars['Boolean']['output']>;
  webBanners: Array<WebBanner>;
};


export type UserRestaurantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type Variant = {
  __typename?: 'Variant';
  catchweightPrice?: Maybe<Scalars['Int']['output']>;
  currentVariantPricing: VariantPricing;
  dimensions: Dimensions;
  id: Scalars['Int']['output'];
  images: Array<Image>;
  itemsCount: Scalars['Int']['output'];
  /** @deprecated use the weight field instead */
  netWeight: Scalars['Float']['output'];
  packageType: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantityDescription: Scalars['String']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type VariantPricing = {
  __typename?: 'VariantPricing';
  id: Scalars['Int']['output'];
  priceLevels: Array<PriceLevel>;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['Int']['output'];
  image?: Maybe<VendorImage>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  settings: VendorSettings;
};

export type VendorImage = {
  __typename?: 'VendorImage';
  hqUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

/** Vendor settings configuration */
export type VendorSettings = {
  __typename?: 'VendorSettings';
  /** Whether search by barcode scanner feature is enabled for the vendor */
  barcodeScanner: Scalars['Boolean']['output'];
  /** Whether disabled platform payments feature is enabled for the vendor */
  disabledPlatformPayments: Scalars['Boolean']['output'];
  /** Whether lowest variant price on product cell feature is enabled for the vendor */
  showLowestVariantPrice: Scalars['Boolean']['output'];
};

export type VolumeDiscount = {
  __typename?: 'VolumeDiscount';
  price: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
};

export type WebBanner = IBanner & {
  __typename?: 'WebBanner';
  appLink?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  webBannerVersions: Array<WebBannerVersion>;
};

export type WebBannerVersion = {
  __typename?: 'WebBannerVersion';
  photo: Photo;
  size: Scalars['String']['output'];
};
